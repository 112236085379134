// 参考 : https://www.ultra-noob.com/blog/2020/54/
import React from 'react'

const GoogleCustomSearch = () => {
  const cx = '547dc8eef214af3d4'

  const gcse = document.createElement('script')
  gcse.type = 'text/javascript'
  gcse.async = true
  gcse.src = 'https://cse.google.com/cse.js?cx=' + cx

  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(gcse, s)

  return <div className="gcse-searchresults-only"></div>
}
export default GoogleCustomSearch
