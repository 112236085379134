// 参考 : https://www.ultra-noob.com/blog/2020/54/
import React, { useState, useEffect } from 'react'

function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <div {...delegated} className="customSearch">
      {children}
    </div>
  )
}

export default ClientOnly
