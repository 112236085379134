import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/react-calendar-heatmap.css'
import '../../static/css/search.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/footer.css'

import React from 'react'
import Seo from '../components/Seo'
import { Header } from '../components/Header'
import { GoogleCustomSearch, ClientOnly } from '../components/Search'
import { Side } from '../components/Side'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import { getParam } from '../util/format'
import loadable from '@loadable/component'

const Script = loadable(() => import('../components/Adsense/Script'))

export default function Search({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  const query = getParam('q', location.search)

  const resultText = '「' + query + '」の検索結果'

  const breadcrumb = [
    {
      link: '/',
      text: 'KURORO BLOG',
    },
    {
      link: '/' + 'search' + '/',
      text: resultText,
    },
  ]

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={resultText}
          url={location.href}
          description={
            'KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          noIndex={true}
          contentType="article"
        />

        <Header location={locatePath} />

        <div className="searchResult">
          <section className="searchResult__list">
            <h1 className="searchResult__title">{resultText}</h1>
            <ClientOnly>
              <GoogleCustomSearch />
            </ClientOnly>
          </section>

          <Side
            category={''}
            articleId={''}
            location={locatePath}
            rankingBottomPC={'7392453758'}
            rankingBottomMobile={'3533099790'}
            betweenSelfAndCategoryLIstPC={'9728793318'}
            betweenSelfAndCategoryLIstMobile={'3885972523'}
            sideFixedPC={'4983771084'}
            sideFixedMobile={'2395785125'}
          />
        </div>
        <Breadcrumb breadcrumb={breadcrumb} />
        <Footer />
      </div>
      <Script />
    </div>
  )
}
